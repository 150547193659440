import React from 'react';
import Layout from '../components/Layouts/Layout';
import SEO from '../components/seo';
import ReactPlayer from 'react-player';
import { Link } from 'gatsby';

// Paste Youtube link between single quotes on line 8 Example --> const URL = 'https://www.youtube.com/watch?v=jdYeqertDN8'
const URL = '';

const Live = () => {
  return (
    <Layout>
      <SEO title='Live YDS Chicago Event' />
      {URL ? (
        <section className='pt-20 bg-gray-900'>
          <ReactPlayer
            url={URL ? URL : ''}
            height='100vh'
            width='100%'
            playing='true'
            controls='true'
          />
        </section>
      ) : (
        <section className='pt-20 bg-gray-900 h-screen'>
          <div className='container mx-auto px-4 pb-20 pt-32 items-center flex flex-col flex-wrap text-white text-5xl justify-center'>
            <h1>No current live event.</h1>
            <div className='mt-20'>
              <Link
                to='/'
                className='bg-orange-500 text-white active:bg-orange-600 text-3xl font-semibold p-4 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
              >
                Back to Home Page
              </Link>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default Live;
